import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

export default function DisclaimerPage() {
    return (
        <Layout>
            <Seo title="Disclaimer" description="Our Disclaimer" />
            <div className="intbanner">
                <StaticImage
                    src="../images/tl-int-banner1.jpg"
                    alt="About Us"
                    placeholder="dominant_color"
                    layout="fullWidth"
                    quality={100}
                />
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12 col-bleed about--content">
                            <h1>
                                Disclaimer
                                <span>
                                    Throughlife Financial Solutions Pty Ltd
                                </span>
                            </h1>
                            <p>
                                The information on this site, including source
                                code, is the property of Throughlife Financial
                                Solutions{' '}
                            </p>
                            <p>Pty Ltd and is subject to Copyright ©</p>
                            <p>
                                Unauthorised use of the information is not
                                permitted.
                            </p>
                            <p>
                                This Internet site has been prepared solely for
                                the purpose of information and is intended as a
                                guide only. The information on this Internet
                                site is provided in good faith and does not
                                constitute, nor is it intended to constitute,
                                advice.
                            </p>
                            <p>
                                This Internet site has been prepared without
                                consideration of the particular financial,
                                business, taxation and insurance objectives,
                                financial situation and needs of recipients. In
                                all cases recipients should conduct their own
                                investigation and analysis of the information
                                contained in this Internet site. No recipient
                                should act on the basis of any matter contained
                                in this Internet site without considering and,
                                if necessary, taking appropriate legal,
                                financial and other professional advice upon the
                                recipient's own particular circumstances.
                            </p>
                            <p>
                                Throughlife Financial Solutions Pty Ltd accepts
                                no responsibility for any loss, cost or expense
                                arising from use of the information in this
                                site.
                            </p>
                            <p>
                                All of the information on this site is subject
                                to change without notice and Throughlife
                                Financial Solutions Pty Ltd cannot guarantee the
                                accuracy or completeness of the information.
                            </p>
                            <p>
                                Advice is provided primarily in Australia and
                                only available to persons or corporations that
                                are an approved client of Throughlife Financial
                                Solutions Pty Ltd.
                            </p>
                            <p>
                                This site may contain information of, or links
                                to, other parties and their opinions are not
                                necessarily those of Throughlife Financial
                                Solutions Pty Ltd. The presence of any link or
                                information of other parties should not be
                                construed as an endorsement of those parties or
                                the accuracy of their information.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}
